import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import UnderlineHeading from '../components/underline';

type IQuestion = {
  question: string;
  answer: string;
};

function Question({ content }: { content: IQuestion }) {
  const { question, answer } = content;
  return (
    <div className="mb-8 md:mb-12">
      <UnderlineHeading marginBottom="mb-2">
        <h3 className="text-xl font-bold">{question}</h3>
      </UnderlineHeading>
      <p>{answer}</p>
    </div>
  );
}

function About() {
  const data: {
    allFaqJson: {
      edges: {
        node: IQuestion;
      }[];
    };
  } = useStaticQuery(graphql`
    query {
      allFaqJson {
        edges {
          node {
            question
            answer
          }
        }
      }
    }
  `);

  return (
    <Layout heading="Frequently Asked Questions">
      <SEO title="About" />
      <div className="py-8">
        {data.allFaqJson.edges.map(q => (
          <Question content={q.node} />
        ))}
      </div>
    </Layout>
  );
}

export default About;
